<template>
    <div class="trend">
        <synthesis-chart class="trend-chart" :patientDetailModel="patientDetailModel" :model="model"
            @onDurationSelected="onDurationSelected"></synthesis-chart>
        <bar-chart class="trend-chart" title="使用时长" :dataArray="useTimeArray"></bar-chart>
        <bar-chart class="trend-chart" title="漏气" :dataArray="leakArray"></bar-chart>
    </div>
</template>
  
<script>
import SynthesisChart from "./synthesis-chart.vue";
import BarChart from "./bar-chart.vue";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        SynthesisChart,
        BarChart
    },

    props: {
        patientDetailModel: Object,
    },

    data() {
        return {
            model: undefined,
            selectedDuration: []
        }
    },

    computed: {
        useTimeArray() {
            const dataArray = this.model?.longTimeTrendChart
            if (dataArray) {
                return dataArray.map((item) => {
                    const dateStr = DateTool.timeStrToStr(item.date, 'MM.DD')
                    const value = item.value ? item.value / 3600 : 0
                    return [dateStr, value, item.value]
                })
            } else {
                return []
            }
        },

        leakArray() {
            const dataArray = this.model?.leakTrendChart
            if (dataArray) {
                let tempArray = dataArray.map((item) => {
                    const dateStr = DateTool.timeStrToStr(item.date, 'MM.DD')
                    const value = item.value ? parseFloat(item.value) : 0
                    return [dateStr, value]
                })
                return tempArray
            } else {
                return []
            }
        }
    },

    watch: {
        patientDetailModel() {
            this.getTrendDetail()
        }
    },

    methods: {
        onDurationSelected(value) {
            this.selectedDuration = value
            this.getTrendDetail()
        },

        async getTrendDetail() {
            if (!this.patientDetailModel) {
                return;
            }
            try {
                const params = {
                    "userId": this.patientDetailModel.model.id,
                    "startTime": DateTool.milliTimestampToStr(this.selectedDuration[0]),
                    "endTime": DateTool.milliTimestampToStr(this.selectedDuration[1] + 86400000)
                }
                const model = await this.$api.getTrendDetail(params)
                if (!this.objectValueAllEmpty(model)) {
                    this.model = model
                }
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        objectValueAllEmpty(object) {
            var isEmpty = true;
            Object.keys(object).forEach((key) => {
                if (object[key]) { isEmpty = false }
            });
            return isEmpty;
        }
    }

}
</script>
  
<style lang="scss" scoped>
.trend {
    width: 100%;


    &-chart {
        box-sizing: border-box;
        padding: 25px 24px;
        margin-bottom: 25px;
    }

    &-empty {
        background-color: white;
        color: black;
        font-size: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-info {
            margin-top: 150px;
        }
    }
}
</style>