<template>
  <div class="report-section">
    <div class="report-section-title">
      <p class="report-section-title-text">{{ title }}</p>
      <slot class="report-section-title-right" name="title-right"></slot>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>

.report-section {
  width: 100%;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-text {
      color: black;
      font-size: 24px;
      font-weight: 600;
      line-height: 73px;
    }
  }
}
</style>