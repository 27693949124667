<template>
    <div class="bar-chart" :style="` height: ${380 * zoom}px `">
        <p class="bar-chart-title">{{ `${title}（${unit}）` }}</p>
        <div class="bar-chart-chart" :id="chartID"
            :style="`width: ${100 / zoom}% ; height: ${308 * zoom}px ;zoom:${1 / zoom};transform:scale(${zoom});transform-origin:0 0`">
        </div>
    </div>
</template>
        
<script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
import { getHexColor } from "@js/common-tool.js";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        ReportSection,
        KeyValueUnit,
    },

    props: {
        title: String,
        dataArray: Array
    },

    data() {
        return {
            // dataArray: [['04.18', 17.5, '1245']]
            // dataArray: [['04.18', 17.5, '1245']]
        }
    },


    computed: {
        zoom() {
            return this.$store.state.zoom
        },

        chartID() {
            return this.title
        },

        unit() {
            switch (this.title) {
                case "使用时长":
                    return "小时"
                case "漏气":
                    return "L/min"
                default:
                    break;
            }
        },

        max() {
            switch (this.title) {
                case "使用时长":
                    return 24
                case "漏气":
                    const leakArray = this.dataArray.map((item) => {
                        return item[1]
                    })
                    const rawMaxLeak = Math.max.apply(null, leakArray);
                    const maxLeak = Math.ceil(rawMaxLeak / 10) * 10
                    return maxLeak
                default:
                    break;
            }
        },

        markLine() {
            var value
            var color
            switch (this.title) {
                case "使用时长":
                    value = 4
                    color = "#7ABCFB"
                    break
                case "漏气":
                    value = 5
                    color = "#F45C52"
                default:
                    break;
            }
            return {
                symbol: "none",
                data: [{ yAxis: value }],
                lineStyle: { color: color },
                label: {
                    position: 'middle',
                    formatter: (item) => {
                        return `${item.value}`;
                    },
                },
            }
        }
    },

    mounted() {
        this.chart = this.$echarts.init(document.getElementById(this.chartID));
        this.drawLine();
    },

    watch: {
        dataArray() {
            this.drawLine()
        }
    },

    methods: {
        drawLine() {
            this.chart.setOption({
                dataset: {
                    source: this.dataArray,
                },
                grid: {
                    left: 50,
                    top: 30,
                    bottom: 25,
                    right: 10,
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        color: getHexColor("rgba(0,0,0,0.6)"),
                        fontSize: 14,
                    },
                    axisLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.25)"),
                        },
                    },
                },
                yAxis: {
                    max: this.max,
                    min: 0,
                    axisLabel: {
                        color: getHexColor("rgba(0,0,0,0.4)"),
                        fontSize: 14,
                        padding: [0, 20, 0, 0]
                    },
                    splitLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.1)"),
                            width: 1
                        }
                    }
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: (params) => {
                        return this.formatterToolTip(params);
                    },
                },
                series: [
                    {
                        type: "bar",
                        barWidth: 25,
                        itemStyle: {
                            color: "#0052D9"
                        },
                        markLine: this.markLine
                    },
                ]
            });
        },

        formatterToolTip(params) {
            const dateStr = params[0].data[0]
            const value = params[0].data[1]
            const marker = `<span style=\"display:inline-block;margin-bottom:2px;margin-right:6px;width:6px;height:6px;background-color:${params[0].color};\"></span>`
            if (value) {
                let displayValue
                switch (this.title) {
                    case "使用时长":
                        displayValue = DateTool.formatSeconds(params[0].data[2])
                        break
                    case "漏气":
                        displayValue = `${value}${this.unit}`
                        break
                    default:
                        break;
                }
                var tip = "";
                tip += dateStr + "<br />";
                tip += marker + this.title + ": " + displayValue + "<br />";
                return tip
            } else {
                return "暂无数据"
            }
        }
    },


};
</script>
        
<style lang="scss" scoped>
.bar-chart {
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;

    &-title {
        color: black;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px
    }
}
</style>