<template>
    <div class="synthesis-chart" :style="` height: ${420 * zoom}px `">
        <div class="synthesis-chart-top">
            <p class="synthesis-chart-top-title">AHI/ODI/Ts90%（%）/平均血氧（%）/最低血氧（%）</p>
            <trend-date-picker :patientDetailModel="patientDetailModel" :model="model"
                @onDurationSelected="onDurationSelected"></trend-date-picker>
        </div>
        <div class="synthesis-chart-content">
            <div id="synthesis-chart"
                :style="`width: ${100 / zoom}%; height: ${388 * zoom}px;zoom:${1 / zoom};transform:scale(${zoom});transform-origin:0 0 `">
            </div>
        </div>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
import { getHexColor } from "@js/common-tool.js";
import TrendDatePicker from "@c/user-main/trend/trend-date-picker.vue";
export default {
    components: {
        TrendDatePicker
    },

    props: {
        model: Object,
        patientDetailModel: Object
    },

    data() {
        return {
            chart: null,
        };
    },

    computed: {
        zoom() {
            return this.$store.state.zoom
        },

        timeArray() {
            return this.model?.ahiTrendChart.map(item => {
                return DateTool.timeStrToStr(item.date, 'MM.DD')
            })
        },

        ahiArray() {
            return this.model?.ahiTrendChart.map(item => {
                return item.value
            })
        },

        odiArray() {
            return this.model?.odiTrendChart.map(item => {
                return item.value
            })
        },

        Ts90Array() {
            return this.model?.tpInTrendChart.map(item => {
                return item.value
            })
        },

        minSPO2Array() {
            return this.model?.minSpo2TrendChart.map(item => {
                return item.value
            })
        },

        avgSPO2Array() {
            return this.model?.spo2TrendChart.map(item => {
                return item.value
            })
        },

        yAxisLeftMax() {
            const maxAHI = Math.max(...this.ahiArray ?? [])
            const maxODI = Math.max(...this.odiArray ?? [])
            const maxTS90 = Math.max(...this.Ts90Array ?? [])
            console.log("🍯", Math.max(maxAHI, maxODI, maxTS90))
            return Math.max(maxAHI, maxODI, maxTS90) > 60 ? 120 : 60
        }
    },

    mounted() {
        this.chart = this.$echarts.init(document.getElementById("synthesis-chart"));
        this.drawLine();
    },

    watch: {
        model() {
            this.drawLine();
        },
    },

    methods: {
        onDurationSelected(value) {
            this.$emit("onDurationSelected", value)
        },

        drawLine() {
            this.chart.setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: (params) => {
                        return this.formatterToolTip(params, 'AHI');
                    },
                },
                legend: {
                    data: ['AHI', 'ODI', 'Ts90%', '平均血氧', '最低血氧'],
                    right: 260,
                    top: 5

                },
                color: ['#0052D9', '#029CD4', '#2BA471', '#F5BA18', '#E37318'],
                grid: {
                    left: 50,
                    top: 60,
                    bottom: 25,
                    right: 50,
                },
                xAxis: {
                    type: 'category',
                    data: this.timeArray,
                    axisLabel: {
                        color: getHexColor("rgba(0,0,0,0.6)"),
                        fontSize: 14,
                    },
                    axisLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.25)"),
                        },
                    },
                },
                yAxis: [
                    {
                        max: this.yAxisLeftMax,
                        min: 0,
                        axisLabel: {
                            color: getHexColor("rgba(0,0,0,0.4)"),
                            fontSize: 14,
                            padding: [0, 20, 0, 0]
                        },
                        splitLine: {
                            lineStyle: {
                                color: getHexColor("rgba(24,24,24,0.1)"),
                                width: 1
                            }
                        },
                    },
                    {
                        max: 100,
                        min: 40,
                        position: 'right',
                        alignTicks: true,
                        axisLabel: {
                            color: getHexColor("rgba(0,0,0,0.4)"),
                            fontSize: 14,
                            padding: [0, 0, 0, 18]
                        }
                    }],
                series: [
                    {
                        name: 'AHI',
                        type: 'bar',
                        data: this.ahiArray,
                        barWidth: 11,
                        yAxisIndex: 0,
                        markLine: {
                            symbol: "none",
                            data: [{ yAxis: 5 }],
                            lineStyle: { color: '#7ABCFB' },
                            label: {
                                position: 'middle',
                                formatter: (item) => {
                                    return `${item.value}`;
                                },
                            },
                        },
                    },
                    {
                        name: 'ODI',
                        type: 'bar',
                        data: this.odiArray,
                        barWidth: 11,
                        yAxisIndex: 0,
                    },
                    {
                        name: 'Ts90%',
                        type: 'bar',
                        data: this.Ts90Array,
                        barWidth: 11,
                        yAxisIndex: 0,
                    },
                    {
                        name: '平均血氧',
                        type: 'line',
                        data: this.avgSPO2Array,
                        yAxisIndex: 1,
                    },
                    {
                        name: '最低血氧',
                        type: 'line',
                        data: this.minSPO2Array,
                        yAxisIndex: 1,
                    }
                ]
            });

            setTimeout(() => {
                window.onresize = () => {
                    this.chart.resize();
                };
            }, 200);
        },

        formatterToolTip(params) {
            const paramsValueArray = params.map((item) => {
                return item.data
            })
            let isAllNull = true
            paramsValueArray.forEach(item => {
                if (item) {
                    isAllNull = false
                    return
                }
            });
            //全部为null不显示
            if (isAllNull) {
                return '暂无数据'
            } else {
                var tip = "";
                if (params != null && params.length > 0) {
                    //title
                    tip += params[0].name + "<br />";
                    for (let i = 0; i < params.length; i++) {
                        //marker
                        let marker
                        switch (params[i].componentSubType) {
                            case "bar":
                                marker = `<span style=\"display:inline-block;margin-bottom:2px;margin-right:6px;width:6px;height:6px;background-color:${params[i].color};\"></span>`
                                break;
                            case 'line':
                                marker = `<span style=\"display:inline-block;margin-bottom:2px;margin-right:6px;width:6px;height:2px;background-color:${params[i].color};\"></span>`
                                break
                            default:
                                break;
                        }
                        //seriesName
                        let seriesName = params[i].seriesName;
                        //unit
                        let unit = ''
                        switch (params[i].seriesName) {
                            case "AHI":
                                break
                            case "ODI":
                                break
                            case "Ts90%":
                            case "平均血氧":
                            case "最低血氧":
                                unit = '%'
                                break
                            default:
                                break;
                        }
                        //value
                        let value = "";
                        const rawValue = params[i].value
                        if (rawValue != null) {
                            value = `${rawValue}${unit}`;
                        }
                        tip += marker + seriesName + ": " + value + "<br />";
                    }
                }
                return tip;
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.synthesis-chart {
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;

    &-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: black;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px
        }

        &-date-picker {
            width: 240px;
            height: 32px;
            @include z-index-3;

            /deep/.el-range-separator {
                color: rgba($color: #000000, $alpha: 0.25);
                line-height: 25px;
            }
        }
    }

    &-content {
        @include z-index-2;
        position: relative;
        top: -30px;
    }
}
</style>