<template>
    <div class="trend-date-picker">
        <el-date-picker class="trend-date-picker-content" v-model="selectedDuration" size="mini" type="daterange"
            value-format="timestamp" range-separator="→" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="pickerOptions" :default-time="['12:00:00', '12:00:00']" :append-to-body="false"
            :clearable="false"></el-date-picker>
    </div>
</template>

<script>
import { DatePicker } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import { filterEmpty } from "@js/empty-tool";

export default {
    components: {
        elDatePicker: DatePicker,
    },

    props: {
        patientDetailModel: Object
    },

    data() {
        return {
            selectedDuration: [],
            bloodOxygenReportDateArray: [],
            breathReportDateArray: [],
            pickerOptions: {
                cellClassName: (time) => {
                    const dateStr = DateTool.milliTimestampToStr(time.getTime(), "YYYY-MM-DD");
                    const isIncludeBloodOxygen =
                        this.bloodOxygenReportDateArray.includes(dateStr);
                    const isIncludeBreath = this.breathReportDateArray.includes(dateStr);
                    if (isIncludeBloodOxygen && !isIncludeBreath) {
                        return "blood-oxygen-date";
                    }
                    if (!isIncludeBloodOxygen && isIncludeBreath) {
                        return "breath-date";
                    }
                    if (isIncludeBloodOxygen && isIncludeBreath) {
                        return "blood-oxygen-date breath-date";
                    }
                },
            },
        };
    },

    watch: {
        selectedDuration(value) {
            this.$emit("onDurationSelected", value)
        }
    },

    async mounted() {
        this.bloodOxygenReportDateArray = await this.getBloodOxygenReportDateList();
        this.breathReportDateArray = await this.getBreathReportDateList();
        this.selectedDuration = this.getInitSelectedDuration()
    },

    methods: {
        getInitSelectedDuration() {
            const endDate = this.getEndDate()
            const startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000)
            const startTimestamp = Date.parse(startDate)
            const endTimestamp = Date.parse(endDate)
            return [startTimestamp, endTimestamp]
        },

        async getBloodOxygenReportDateList() {
            try {
                const params = {
                    patientId: this.patientDetailModel.model.id,
                };
                return await this.$api.getBloodOxygenReportDateList(params);
            } catch (error) {
                return []
            }
        },

        async getBreathReportDateList() {
            try {
                const params = filterEmpty({
                    uid: this.patientDetailModel.model.id,
                    hospitalId: this.patientDetailModel.model.hospitalId,
                });
                return await this.$api.getReportDateList(params);
            } catch (error) {
                return [];
            }
        },

        getEndDate() {
            //按照报告开始时间排序的，因此出报告时间需要排序
            const breathEndDateStr = this.breathReportDateArray?.reduce((prev, current) => {
                return prev > current ? prev : current
            }, "");
            const bloodEndDateStr = this.bloodOxygenReportDateArray[0]
            const todayDateStr = DateTool.dateToStr(new Date(), "YYYY-MM-DD")
            let endDateStr
            if (!breathEndDateStr && !bloodEndDateStr) {
                endDateStr = todayDateStr
            } else if (breathEndDateStr && !bloodEndDateStr) {
                endDateStr = breathEndDateStr
            } else if (bloodEndDateStr && !breathEndDateStr) {
                endDateStr = bloodEndDateStr
            } else {
                if (breathEndDateStr > bloodEndDateStr) {
                    endDateStr = breathEndDateStr
                } else {
                    endDateStr = bloodEndDateStr
                }
            }
            return new Date(`${endDateStr} 12:00:00`)
        }
    }

}
</script>

<style lang="scss" scoped>
.trend-date-picker {
    &-content {
        width: 240px;
        height: 32px;
        @include z-index-3;

        /deep/.el-range-separator {
            color: rgba($color: #000000, $alpha: 0.25);
            line-height: 25px;
        }

        /deep/.blood-oxygen-date>div ::after {
            content: "";
            position: absolute;
            right: 1px;
            top: 2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #ff6d4a;
        }

        /deep/.breath-date>div ::before {
            content: "";
            position: absolute;
            left: 1px;
            top: 2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #4da0fb;
        }
    }
}
</style>